export const MAX_LENGTH = {
  OPERATOR: {
    PASSWORD: 16,
    FULL_NAME: 200,
    USERNAME: 60,
  },
  CUSTOMER: {
    USERNAME: 64,
    FULL_NAME: 80,
    PASSWORD: 32,
    MOBILE: 20,
    SHORT_NAME: 20,
    ENG_SHORT_NAME: 50,
    ENG_FULL_NAME: 80,
    PROFESSION: 40,
    REMARK: 300,
    TIN_TAXNO: 120,
    GIIN_TAXNO: 120,
    TAX_FORM_TYPE: 80,

    TAX: {
      JURISDICTION: 20,
      TAX_NO: 20,
      REASON: 200,
      REMARK: 300,
    },
    CONCAT: {
      POST_CODE: 80,
      CONTACT_CONTENT: 300,
      ADDRESS_INFO: {
        COUNTRY: 80,
        PROVINCE: 20,
        CITY: 40,
        LINE: 160,
      },
    },
    BANK_CARD: {
      BANK: 10,
      CURRENCY: 10,
      BANK_ACCOUNT_NAME: 80,
      NUMBER: 60,
      BRANCH_ADDRESS: 200,
      BANK_NO: 60,
      CURRENCY_TYPE: 20,
      ACCOUNT_TYPE: 20,
      SIGNED_STATUS: 20,
      BANK_BRANCH: 80,
      CERTIFICATE_TYPE: 60,
      CERTIFICATE_NO: 60,
    },
    IDENTIFY: {
      TYPE: 20,
      NUMBER: 60,
      FULL_NAME: 80,
    },
  },
  IT: {
    NAME: 32,
    APP_KEY: 200,
    APP_SECRET: 200,
    DEMO_APP_KEY: 200,
    DEMO_APP_SECRET: 200,
    IOS_APP_LINK: 256,
    ANDROID_APP_LINK: 256,
    WINDOWS_DESKTOP_APP_LINK: 256,
    MAC_X86_DESKTOP_APP_LINK: 256,
    MAC_ARM_DESKTOP_APP_LINK: 256,
    WEB_LINK: 256,
    H5_LINK: 256,
  },
  IT_RECEIVE: {
    NAME: 200,
    RECEIVE_METHOD: 15,
    BANK: 10,
    CRYPTO_NETWORK: 10,
    BRANCH_ADDRESS: 200,
    BANK_ACCOUNT_NAME: 80,
    NUMBER: 60,
    IMAGE_LOCATION: 200,
  },
  AGENT: {
    NAME: 32,
    NOTIFY_EMAIL: 64,
    NOTIFY_LANG: 10,
    EXCLUSIVE_ACCOUNT_ID: 30,
  },
  FX_RATE_TEMPLATE: {
    NAME: 32,
  },
  CONFIG: {
    KEY: 50,
    VALUE: 500,
  },
  CURRENCY: 10,
  COMMENT: 200,
  TRANSFER: {
    REMARK: 200,
  },
  USDT_ERC20: {
    NETWORK_URLS: 800,
  },
  PAYMENT_CHANNEL: {
    NAME: 64,
    REMARK: 512,
  },
  PAYMENT_GATEWAY: {
    CUSTOMER_NAME: 128,
    USERNAME: 128,
    SECRET_KEY: 512,
    REMARK: 512,
  },
};

export const MIN_LENGTH = {
  OPERATOR: {
    PASSWORD: 6,
  },
  CUSTOMER: {
    PASSWORD: 8,
    PS_RESTRICT_PASSWORD: 8,
  },
};

export const DECIMAL_LENGTH = {
  RATE: { precision: 18, scale: 9 },
  USDT_ERC20: {
    MAX_AMOUNT: { precision: 15, scale: 2 },
  },
  USDT_TRC20: {
    MAX_AMOUNT: { precision: 15, scale: 2 },
  },
  PAYMENT_CHANNEL: {
    MIN_TRANSACTION_AMOUNT: { precision: 18, scale: 2 },
    MAX_TRANSACTION_AMOUNT: { precision: 18, scale: 2 },
    USE_FEE_VALUE: { precision: 18, scale: 2 },
    PAYMENT_FEE_VALUE: { precision: 18, scale: 4 },
  },
};
